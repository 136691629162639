<template>
  <div>
    <el-steps :active="active" simple>
      <el-step
        title="选择运营商"
        class="el-step-0"
        style="cursor: hand"
      >
      </el-step>
      <el-step title="用户登录" ></el-step>
      <el-step title="创建集群" ></el-step>
      <el-step title="配置节点" ></el-step>
      <el-step title="应用配置" ></el-step>
      <el-step title="组件配置" ></el-step>
      <el-step title="创建状态" ></el-step>
    </el-steps>
    </div>
</template>

<script>
export default {
  computed: {
    active() {
      return this.$store.getters.processActive;
    },
  },

  methods: {
    handle_1() {
      const formKey = this.$store.getters.cloudBrand;
      if (formKey) {
        // 表示已经选择过云的提供商
        this.$router.push("/form/" + formKey + "Access");
      }
    },

    handle_2() {
      const formKey = this.$store.getters.cloudBrand;
      // const hasLogedIn =
      //   !!this.$store.getters.reqData["accessKeyID"] &&
      //   !!this.$store.getters.reqData["accessKeySecret"];
      // if (hasLogedIn) {
      //   this.$router.push("/form/" + formKey + "Access/" + formKey + "Cluster");
      // } else {
      //   // do nothing
      // }
      if (formKey) {
        this.$router.push("/form/" + formKey + "Access/" + formKey + "Cluster");
      }
    },

    handle_3() {
      const formKey = this.$store.getters.cloudBrand;
      // const driverOp = this.$store.getters.reqData["driverOptions"];
      // if (!!driverOp) {
      if (formKey) {
        this.$router.push(
          "/form/" + formKey + "Access/" + formKey + "Cluster/node"
        );
      }

      // } else {
      //   // do nothing
      // }
    },

    handle_4() {
      const formKey = this.$store.getters.cloudBrand;
      // const nodeOp = this.$store.getters.reqData["nodesOptions"];
      // if (!!nodeOp.length) {
      //   this.$router.push(
      //     "/form/" + formKey + "Access/" + formKey + "Cluster/node/appConfig"
      //   );
      // } else {
      //   // do nothing
      // }
      if (formKey) {
        this.$router.push(
          "/form/appCommonConfig"
        );
      }
    },

    handle_5() {
      const formKey = this.$store.getters.cloudBrand;
      if (formKey) {
        this.$router.push(
          "/form/appCustomConfig"
        );
      }
    },
  },
};
</script>

<style>
</style>