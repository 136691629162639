<template>
  <div class="form">
    <ProcessBar />
    <router-view
    class="childForm"
    ></router-view>
  </div>
</template>

<script>
import ProcessBar from "./ProcessBar.vue";
export default {
  components: { ProcessBar },
};
</script>

<style>
.form{
  text-align: center;
}
.childForm{
  display: inline-block;
  width: 90%;
  margin: 20px;
}
</style>